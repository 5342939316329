<template>
  <div class="container">
    <main class="modal-card">
      <header class="modal-card-head has-text-centered">
        <h1 class="modal-card-title">{{ $t("access_code.access_code_title") }}</h1>
        <p class="modal-card-subtitle">{{ $t("access_code.access_code_description") }}</p>
      </header>

      <section class="modal-card-body">
        <access-code-form
          :showProgress="isRequesting"
          :errorMessage="pluckErrorMessage"
          @submit="validateAccessCode"
          @clear-error="clearError"
        ></access-code-form>
      </section>

      <footer class="modal-card-foot pt-3">
        <div class="has-text-centered">
          {{ eventTitle }}
        </div>
      </footer>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import AccessCodeForm from "@/web/components/forms/AccessCodeForm";
import Constants from "@/web/constants";
import { pluckError } from "@/shared/utils/pluck-error";

export default {
  mixins: [RequestMixin, RedirectMixin],

  name: "AccessCode",

  components: {
    AccessCodeForm,
  },

  requests: {
    async validateAccessCode(formData) {
      await this.checkAccessCode(formData);
      await this.refreshEventData();
      await this.checkLoginRequirement();

      const defaultRoute = { name: Constants.ROUTE_MAIN_INTRODUCTION };
      this.nextRedirect(defaultRoute);
    },

    async refreshEventData() {
      await this.loadEventData();
    },
  },

  methods: {
    ...mapActions(["checkAccessCode", "loadEventData"]),

    clearError() {
      this.error = null;
    },

    async checkLoginRequirement() {
      // Redirect on required user login
      if (this.requiresLogin && !this.isEventJoined) {
        await this.$router.replace({ name: Constants.ROUTE_LOGIN });
        throw new Error("Event requires active login");
      }
    },
  },

  computed: {
    ...mapGetters(["requiresLogin"]),
    ...mapGetters("auth", {
      isEventJoined: "isJoined",
    }),
    ...mapState(["event"]),

    pluckErrorMessage() {
      return this.error ? pluckError(this.error) : null;
    },

    eventTitle() {
      return this.event.title;
    },
  },
};
</script>
