<template>
  <div class="magic-link-banner" v-prevent-parent-scroll v-if="mobileOperatingSystem && link">
    <div class="magic-link-banner-text-container">
      <span>{{ $t("auth.magic_link_banner_text") }}</span>
    </div>
    <a :href="link" target="_self" class="button is-primary is-outlined is-small mr-1 is-flex-shrink-1">
      {{ $t("auth.magic_link_banner_app_button") }}
    </a>
    <button class="button is-light is-small is-outlined is-flex-shrink-1" @click="handleDeepLink">
      {{ $t("auth.magic_link_banner_web_button") }}
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Constants from "@/web/constants";

export default {
  name: "MagicLinkBanner",

  computed: {
    ...mapState(["event"]),

    mobileOperatingSystem() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }

      return null;
    },

    link() {
      if (this.mobileOperatingSystem === "ios") {
        return this.iosLink;
      } else if (this.mobileOperatingSystem === "android") {
        return this.androidLink;
      } else {
        return null;
      }
    },

    iosLink() {
      if (!this.event || !this.event.apple_bundle_id || !this.queryParamsDeepLink) {
        return null;
      }
      return !!this.queryParamsDeepLink && this.queryParamsDeepLink;
      // return !!this.queryParamsDeepLink && `${this.event.apple_bundle_id}://${this.queryParamsDeepLink}`;
    },

    androidLink() {
      if (!this.queryParamsDeepLink) {
        return null;
      } else {
        let urlWithoutHost = this.queryParamsDeepLink.split("//")[1];
        return `intent://${urlWithoutHost}#Intent;scheme=https;action=android.intent.action.VIEW;S.browser_fallback_url=${window.location.href};end`;
      }
    },

    queryParams() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return Object.entries(Object.fromEntries(urlSearchParams.entries()));
    },

    queryParamsDeepLink() {
      const param = this.queryParams.find(([key, value]) => key === "mobile_deep_link");
      return param && param[1];
    },
  },

  methods: {
    handleDeepLink() {
      window.open(location.protocol + "//" + location.host + location.pathname, "_self");
    },
  },
};
</script>

<style scoped lang="scss">
.magic-link-banner {
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #202131;
  font-size: 16px;
  line-height: 109%;
  color: white;
  white-space: pre-wrap;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 8px;
  padding-top: 8px;
  text-align: center;

  .magic-link-banner-text-container {
    margin-top: 8px;
    margin-bottom: 8px;
    flex: 1 0 100%;
    font-weight: bold;
    text-align: center;
  }

  .is-bold {
    font-weight: 700;
  }

  .is-semibold {
    font-weight: 500;
  }

  .magic-link-banner-image {
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button {
  font-size: 14px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>
