<template>
  <div>
    <magic-link-banner></magic-link-banner>
    <div class="is-relative">
      <div class="container pb-2 mt-2 pt-1">
        <div class="has-text-centered">
          <div class="is-inline-flex has-logo is-clipped" :class="{ box: eventLogoWithOutline }">
            <figure class="image is-centered" :class="figureClass">
              <img class="logo-image" v-if="logoUrl" :src="logoUrl" />
              <MaLogoSquare class="svg-icon" v-else></MaLogoSquare>
            </figure>
          </div>
        </div>

        <header class="has-text-centered">
          <h1 class="modal-card-title">{{ eventName }}</h1>
        </header>

        <slot></slot>
      </div>
      <nav-locales class="auth-locales-container" v-if="localesEnabled"></nav-locales>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NavLocales from "../../locale/NavLocales";
import MaLogoSquare from "@/assets/meetingapplication/ma_logo_square.svg";
import MagicLinkBanner from "@/web/components/magiclink/MagicLinkBanner";

export default {
  name: "AuthorizationLayout",
  components: { MagicLinkBanner, NavLocales, MaLogoSquare },
  computed: {
    ...mapState(["event"]),
    ...mapGetters(["eventLogoWithOutline"]),
    ...mapGetters("locales", ["localeSelectionEnabled"]),

    logoUrl() {
      const logo = this.event && this.event.logo;
      return logo && logo.file_thumbnail_200_url;
    },

    eventName() {
      return (this.event && this.event.title) || this.$t("mobile_app_info.meeting_application_app");
    },

    figureClass() {
      return "is-80x80";
    },

    localesEnabled() {
      return !!this.event || (this.event && this.localeSelectionEnabled);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1000px;
}

.auth-locales-container {
  position: absolute;
  top: 1.7rem;
  right: 10vw;
}
</style>
