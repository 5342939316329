import { mapGetters } from "vuex";
import Constants from "@/web/constants";
import RedirectMixin from "@/web/mixins/RedirectMixin";

export default {
  mixins: [RedirectMixin],

  computed: {
    ...mapGetters("auth", ["isAuthorized", "isJoined"]),
  },

  mounted() {
    if (this.isAuthorized && this.isJoined) {
      this.nextRedirect({ name: Constants.ROUTE_MAIN_INTRODUCTION });
    }
  },
};
